<template>
    <div class="contaner_el">
        <new-header :config="headerConfig"></new-header>
        <div class="info_box">
            <div class="select_text">变更合同选择：</div>
            <div class="select_content" @click="selectBtn">选择合同+</div>

            <div class="select_text" v-if="contentList.length != 0">已选合同：</div>
            <div class="content_list_info">
                <div class="info_list" v-for="(item, index) in contentList" :key="index">
                    <img @click="offBtn(index)" src="../../assets/cloneBtn2.png" />
                    <div>编号:{{ item.contract_no }}</div>
                    <div class="order_box">
                        <div>合同名称：{{ item.contract_name }}</div>
                        <div>工分：{{ item.contract_shares }}</div>
                    </div>
                </div>
            </div>

            <div class="select_text" style="margin-top: 45px;">用户账户：</div>
            <div class="input_box">
                <input v-model="phone" />
                <div @click="clickBtn">点击核验</div>
            </div>
            <van-loading v-if="loadingShow" type="spinner" size="24px" />

            <div v-if="userInfo.length > 0">
                <van-radio-group v-model="user_radio">
                    <div class="user_card" v-for="(v, i) in userInfo" :key="i" >
                        <div>
                            <div class="user_text">会员姓名：{{ v.union_shareholder_info.name }}</div>
                            <div class="user_text">会员身份证：{{ v.union_shareholder_info.Idcard }}</div>
                        </div>
                        <div><van-radio :name="v.user_id"></van-radio></div>
                    </div>
                </van-radio-group>
            </div>

            <div class="price">服务费：{{ orderData.order_amount || '' }}</div>

            <div class="order_text" @click="selectOrder">订单抵扣 ></div>

            <!-- 选择订单抵扣 -->
            <div class="order_discoun" v-if="orderData.ordergoods">
                <div class="discoun">
                    <img class="discoun_img" :src="orderData.ordergoods.goods_image" />
                    <div class="text_info">
                        <div class="title">{{ orderData.ordergoods.goods_name }}</div>
                        <div>订单编号：{{ orderData.order_sn }}</div>
                        <div>订单时间：{{ orderData.add_time }}</div>
                        <div>订单金额：{{ orderData.order_amount }}</div>
                    </div>
                </div>
                <div class="status_text">已完成</div>
            </div>

            <div class="submit_el" @click="submitBtn">提交</div>
        </div>

        <!-- 提示弹窗 -->
        <van-dialog v-model="show" :show-confirm-button="false">
            <div class="cancel_box">
                <img src="https://cdn.health.healthplatform.xyz/FsweN_GNOrZLicejPFZmx0rHnVBq" />
                <div class="text_box">
                    <img @click="cloneBtn" src="../../assets/cloneBtn2.png" />
                    <div class="message_title">温馨提示</div>
                    <div class="message_text">请确认信息，数据将转移给用户</div>
                    <div class="message_text1">{{ phone }}</div>
                    <div class="message_text">是否提交</div>
                    <div class="sure_btn" @click="sureBtn">确认</div>
                </div>
            </div>
        </van-dialog>
    </div>
</template>
<script type>
import newHeader from "@/components/newHeader";
import { verify_api, electron_shares_change_api } from "@/api/alteration";
import valid from "@/utils/valid";
import { Toast } from 'vant';
export default {
    data() {
        return {
            headerConfig: {
                show: true,
                title: "信息填写",
                showKeufu: true,
            },
            loadingShow: false,
            contentList: [],
            phone: '',
            show: false,
            radioConfig: {
                value: '',
                list: [
                    { checked: true, title: '增值兑换', ladel: 1 },
                    { checked: false, title: '分享补金兑换', ladel: 2 },
                ]
            },
            submitState: true,
            userInfo: [],//用户信息
            user_radio: null, //选中股东id
            orderData: {},//订单数据
        };
    },
    components: {
        newHeader,
    },
    created() {
        document.title = "";
        // 选择合同
        if (localStorage.getItem("contentList")) {
            this.contentList = JSON.parse(localStorage.getItem("contentList"))
        }

        // 选择订单
        if (JSON.parse(localStorage.getItem("orderData"))) {
            this.orderData = JSON.parse(localStorage.getItem("orderData"));
        }
    },
    methods: {
        submitBtn() {
            if (!this.contentList.length > 0) return this.$toast('请选择合同')
            if (!Object.keys(this.orderData).length > 0) return this.$toast('请选择订单')
            if (!this.user_radio) return this.$toast('请选择会员')
            this.show = true
        },
        // 数据提交
        sureBtn() {
            if (!this.submitState) return false;
            let data = {
                contract_info: [],
                shares_buyer_info: {
                    phone: '',
                    username: '',
                    identity: '',
                    user_id: this.user_radio
                },
                order_info: [
                    {
                        order_id: this.orderData.order_id,
                        order_sn: this.orderData.order_sn,
                        order_amount: this.orderData.order_amount,
                        goods_image: this.orderData.ordergoods.goods_image,
                        goods_name: this.orderData.ordergoods.goods_name,
                        add_time: this.orderData.add_time,
                    }
                ],
            };
            this.contentList.forEach(e => {
                data.contract_info.push(
                    {
                        contract_id: e.contract_id,
                        contract_name: e.contract_name,
                        contract_no: e.contract_no
                    }
                )
            });
            this.submitState = false;
            electron_shares_change_api(data).then(res => {
                this.submitState = true;
                this.user_radio = null
                if (res.code == 0) {
                    localStorage.removeItem("contentList");
                    localStorage.removeItem("orderData");
                    this.$router.push(`./nextAuditNew?id=${res.data.audit_id}`);
                } else {
                    Toast({
                        message: res.error,
                        icon: "info",
                        duration: 1000
                    });
                }
            })


        },
        cloneBtn() {
            this.show = false
        },
        selectBtn() {
            this.$router.push("./optionContract")
        },
        // 选择订单
        selectOrder() {
            this.$router.push("./orderDiscount")
        },
        // 删除已选合同
        offBtn(index) {
            this.contentList.splice(index, 1);
            localStorage.setItem("contentList", JSON.stringify(this.contentList));
        },
        // 手机号验证
        clickBtn() {
            this.loadingShow = true;
            if (!valid.isMobile(this.phone)) {
                Toast("请输入正确的手机号");
                this.loadingShow = false
                return false
            }
            this.user_radio = null
            let data = {
                phone: this.phone
            }
            verify_api(data).then(res => {
                if (res.code == 0) {
                    this.loadingShow = false
                    this.userInfo = res.data;
                }
            })
        },
    }
};
</script>
<style lang="less" scoped>
.contaner_el {
    background-color: #F9F9FB;

    .cancel_box {
        width: 100%;
        height: 450px;
        // background: linear-gradient(180deg, #A6C9FF 0%, #FFFFFF 50%);
        border-radius: 30px 30px 30px 30px;
        position: relative;
        text-align: center;
        // padding: 51px 22px 20px;
        box-sizing: border-box;

        img {
            width: 100%;
            height: 100%;
        }

        .text_box {
            img {
                width: 24px;
                height: 24px;
                position: absolute;
                right: -40px;
                top: -20px;
            }

            >div {
                margin-bottom: 26px;
            }

            .message_title {
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                font-size: 40px;
                color: #1A1A1A;
                margin-bottom: 50px;
            }

            .message_text {
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 32px;
                color: rgba(0, 0, 0, 0.8);
                text-align: center;
            }

            .message_text1 {
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 32px;
                color: #0091FF;
            }

            .sure_btn {
                width: 180px;
                height: 66px;
                background: #0091FF;
                border-radius: 50px 50px 50px 50px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 26px;
                color: #FFFFFF;
                text-align: center;
                line-height: 66px;
                margin-top: 40px;
                margin: 0 auto;
            }
        }

        >div {
            position: absolute;
            left: 17%;
            top: 51px;
        }
    }

    .order_discoun {
        width: 620px;
        height: 160px;
        background: #F6F6F6;
        border-radius: 10px 10px 10px 10px;
        padding: 20px 24px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        .status_text {
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 24px;
            color: #0091FF;
            margin-top: 30px;
        }

        .discoun {
            display: flex;
            justify-content: space-between;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 20px;
            color: rgba(26, 26, 26, 0.8);

            .text_info {
                margin-left: 13px;
                margin-top: 0;

                div {
                    line-height: 30px;
                }

                .title {
                    font-family: PingFang SC, PingFang SC;
                    font-weight: bold;
                    font-size: 22px;
                    color: #1A1A1A;
                }
            }

            .discoun_img {
                width: 121px;
                height: 121px;
                border-radius: 6px 6px 6px 6px;
                background-color: skyblue;
            }
        }
    }

    .info_box {
        width: 680px;
        min-height: calc(100vh - 162px);
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
        border-radius: 20px 20px 20px 20px;
        margin: 35px 35px 100px;
        box-sizing: border-box;
        padding: 52px 45px;
        position: relative;

        .content_list_info {
            .info_list {
                width: 620px;
                height: 120px;
                background: #F6F6F6;
                border-radius: 10px 10px 10px 10px;
                padding: 31px 21px;
                box-sizing: border-box;
                position: relative;
                margin-bottom: 15px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 24px;
                color: rgba(0, 0, 0, 0.7);

                .order_box {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 15px;
                }

                img {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    right: 12px;
                    top: 12px;
                    z-index: 9;
                }
            }
        }

        .input_box {
            display: flex;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 26px;
            color: #0091FF;
            line-height: 72px;

            input {
                width: 470px;
                height: 72px;
                border: none;
                background: #F6F6F6;
                border-radius: 10px 10px 10px 10px;
                margin-right: 7px;
                color: #000;
                padding: 0 0 0 20px;
                box-sizing: border-box;
            }

        }

        .user_card{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #EBEDF0;
            box-sizing: border-box;
            padding-bottom: 10px;
        }

        .user_text {
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 26px;
            color: rgba(0, 0, 0, 0.7);
            margin-top: 20px;
        }

        .price {
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 26px;
            color: #1A1A1A;
            margin: 53px 0 39px 0;
        }

        .order_text {
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 26px;
            color: #0091FF;
            margin-bottom: 41px;
        }

        .select_text {
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 26px;
            color: #1A1A1A;
            margin-bottom: 22px;
        }

        .select_content {
            width: 590px;
            height: 72px;
            background: #302D2C;
            border-radius: 10px 10px 10px 10px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 26px;
            color: #FFFFFF;
            text-align: center;
            line-height: 72px;
            margin-bottom: 72px;
        }
    }

    .submit_el {
        margin: 76px 85px 0;
        width: 450px;
        height: 80px;
        background: linear-gradient(180deg, #252324 0%, #3C3633 100%), #2F2B2B;
        border-radius: 12px 12px 12px 12px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 28px;
        color: #FFFFFF;
        text-align: center;
        line-height: 80px;
    }
}
</style>
